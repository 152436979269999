<template>
    <div id="main-menu">
        <div id="menu-items" class="pt-0" :class="{ show: toggled }" @click="toggled = false">
            <slot></slot>
        </div>

        <b-button
            id="main-menu-toggle"
            variant="primary"
            type="button"
            aria-label="Toggle navigation"
            class="float-right"
            aria-controls="nav-collapse"
            @click="toggled = !toggled"
        >
            <b-icon-list></b-icon-list>
        </b-button>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MainMenu',
    data() {
        return {
            toggled: false,
        }
    },
    mounted() {
        document.addEventListener('click', (evt) => {
            const flyoutElement = document.getElementById('main-menu')
            let targetElement = evt.target // clicked element

            do {
                if (targetElement == flyoutElement) {
                    // This is a click inside. Do nothing, just return.
                    return
                }
                // Go up the DOM
                targetElement = targetElement.parentNode
            } while (targetElement)

            // This is a click outside.
            this.toggled = false
        })
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

#main-menu {
    display: flex;
    flex-grow: 1;
    @media (max-width: 992px) {
        flex-direction: row-reverse;
    }

    #main-menu-toggle {
        display: none !important;

        @media (max-width: 992px) {
            display: block !important;
            float: right;
            margin-left: $spacer;
        }
    }

    #menu-items {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 992px) {
            display: none;
        }

        &.show {
            @media (max-width: 992px) {
                padding: $spacer;
                z-index: 1001;
                display: block !important;
                position: absolute;
                top: 100%;
                background: $purple;
                right: 0;
                width: 225px;
            }
        }
    }
}
</style>
